<template>
  <div class="w-full" v-loading="loading">
    <el-table
      :data="datas"
      border
      :key="JSON.stringify(columns)"
      ref="elTable"
      highlight-current-row
      :height="tableHeight - 2"
      @row-click="onRowClick"
      @selection-change="onSelectionChange"
    >
      <el-table-column v-if="$scopedSlots['table-expand']" type="expand">
        <template slot-scope="{ row }">
          <slot :name="'table-expand'" :row="row"></slot>
        </template>
      </el-table-column>
      <el-table-column
        v-if="isIndex"
        type="index"
        fixed="left"
        align="center"
        label="#"
        width="40"
      >
      </el-table-column>
      <el-table-column
        v-if="isSelection"
        type="selection"
        width="55"
        fixed="left"
        align="center"
      >
      </el-table-column>
      <el-table-column
        v-for="(item, index) in columns"
        :fixed="item['fixed'] || false"
        :key="index"
        :label="item['label']"
        :width="item['width']"
        :prop="item['field']"
        :align="item['align']"
      >
        <template slot="header">
          <div v-if="$scopedSlots['table-header-' + item['field']]">
            <slot :name="'table-header-' + item['field']" :header="item"></slot>
          </div>
          <div v-else>
            {{ item["label"] }}
          </div>
        </template>
        <template slot-scope="scope">
          <template v-if="$scopedSlots['table-item-' + item['field']]">
            <slot
              :name="'table-item-' + item['field']"
              :row="scope.row"
              :$index="scope.$index"
            ></slot>
          </template>
          <template v-else>
            {{
              !!scope.row[item["field"]] || scope.row[item["field"]] === 0
                ? scope.row[item["field"]]
                : "-"
            }}
          </template>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      ref="page"
      v-if="isPage"
      class="p-2 text-right"
      background
      :page-size="page.pageSize"
      :current-page.sync="page.pageIndex"
      layout="total,prev, pager, next"
      :total="page.total"
      @current-change="onPage"
      @prev-click="onPage"
      @next-click="onPage"
    >
    </el-pagination>
  </div>
</template>
<script>
export default {
  name: "",
  components: {},
  props: {
    root: {
      type: Object,
      default: () => {},
    },

    // 是否有分页
    isPage: {
      type: Boolean,
      default: true,
    },
    // 是否有搜索
    isSearch: {
      type: Boolean,
      default: true,
    },
    // 是否有操作区
    isAction: {
      type: Boolean,
      default: true,
    },
    // 是否显示序号
    isIndex: {
      type: Boolean,
      default: true,
    },
    // 是否多选
    isSelection: {
      type: Boolean,
      default: true,
    },
    // 表格内容关键字
    tableKeyword: {
      type: String,
      default: "",
    },
    //表格表头
    columns: {
      type: Array,
      default: () => [],
    },
    // 表格数据
    rows: {
      type: Array,
      default: () => [],
    },
    // api
    api: {
      type: String,
      default: "",
    },
    //params 参数
    onParams: Function,
    // 结果
    onResult: {
      type: Function,
      default: () => {},
    },

    //高度
    height: {
      type: Number,
      default: 200,
    },
  },
  computed: {
    datas: {
      get() {
        return this.rows;
      },
      set(val) {
        this.$emit("update:rows", val);
      },
    },
  },
  data() {
    return {
      loading: false,
      tableHeight: this.height,
      page: {
        total: 0,
        pageIndex: 1,
        pageSize: 25,
      },
    };
  },
  created() {
    this.getHeight();
    window.addEventListener("resize", this.getHeight());
  },
  activated() {
    this.getHeight();
  },
  mounted() {},

  methods: {
    // 表格多选change事件
    onSelectionChange(data) {
      this.$emit("onSelectionChange", data);
    },

    // 行单击事件
    onRowClick(data, columns) {
      if (columns.type !== "selection") {
        this.$refs.elTable.clearSelection(); //  清空用户选择
        this.$refs.elTable.toggleRowSelection(data, true); // 单击选中当前条
      } else {
        this.$refs.elTable.toggleRowSelection(data); // 单击选中当前条
      }
      this.$emit("onRowClick", data);
    },

    // 设置高亮行
    onSetCurrentRow(row) {
      this.$refs["elTable"].setCurrentRow(row);
    },

    // 分页事件
    onPage(page) {
      this.page.pageIndex = page;
      this.onQuery();
    },

    // 重置分页查询
    async onFind() {
      this.page.pageIndex = 1;
      await this.onQuery();
    },

    // 查询table数据
    async onQuery() {
      let params = this.onParams();
      for (let key in this.page) {
        if (!!params[key] || params[key] === 0) this.page[key] = params[key];
      }

      if (this.isPage) {
        params = Object.assign(this.page, params);
      }
      this.loading = true;
      await this.$cloud
        .get(this.api, { ...params })
        .then((res) => {
          this.loading = false;
          this.onResult(res);
          if (this.tableKeyword) {
            this.datas.splice(0, this.datas.length, ...res[this.tableKeyword]); // 直接用”=“,页面数据不更新
            this.page.total = res[this.tableKeyword]?.length || 0;
          } else if (res.list) {
            this.datas.splice(0, this.datas.length, ...res.list); // 直接用”=“,页面数据不更新
            this.page.total = res.total;
          } else {
            this.datas.splice(0, this.datas.length, ...res); // 直接用”=“,页面数据不更新
          }
        })
        .catch((err) => {
          this.loading = false;
          this.$message.error(err);
        });
    },

    // 获取表格高度
    getHeight() {
      // 当高度不等于默认值时，则不动态计算高度
      if (this.height !== 200) {
        return;
      }
      this.$nextTick(() => {
        setTimeout(() => {
          let searchHeight = 0;
          if (this.isSearch) {
            searchHeight =
              this.root.$refs["searchbox"]?.clientHeight !== undefined
                ? this.root.$refs["searchbox"]?.clientHeight
                : this.root.$refs["searchbox"]?.$el.clientHeight;
          }

          let actionHeight = 0;
          if (this.isAction) {
            actionHeight =
              this.root.$refs["actionbox"]?.clientHeight !== undefined
                ? this.root.$refs["actionbox"]?.clientHeight
                : this.root.$refs["actionbox"]?.$el.clientHeight;
          }

          let pageHeight = 0;
          if (this.isPage) {
            pageHeight =
              this.$refs["page"]?.clientHeight !== undefined
                ? this.$refs["page"]?.clientHeight
                : this.$refs["page"]?.$el.clientHeight;
          }

          let mainbox =
            this.root.$refs["mainbox"]?.clientHeight !== undefined
              ? this.root.$refs["mainbox"]?.clientHeight
              : this.root.$refs["mainbox"]?.$el.clientHeight;
          this.tableHeight = mainbox - searchHeight - actionHeight - pageHeight;
          if (!this.tableHeight) {
            this.tableHeight = this.height;
          }
        });
      });
    },
  },
};
</script>
<style lang="scss" scoped></style>
