/**
 * ======================================
 * 说明： 管理员评分申诉管理配置
 * 作者： Silence
 * 文件： config.js
 * 日期： 2023/5/9 13:13
 * ======================================
 */
export const columns = [
  {
    field: "User",
    label: "申诉教师",
    align: "left",
    width: "120",
  },
  {
    field: "Student",
    label: "申诉对象",
    align: "left",
    width: "120",
  },
  {
    field: "no",
    label: "申诉内容",
    align: "center",
    width: "auto,",
  },
  {
    field: "reason",
    label: "申诉原因",
    align: "left",
    width: "160,",
  },
  {
    field: "status",   // 申述状态 YES通过 NO 不通过 PROCESS 审核中
    label: "状态",
    align: "left",
    width: "160,",
  },

  {
    field: "created_time",
    label: "申诉时间",
    align: "left",
    width: "160",
  },
  {
    field: "updated_time",
    label: "审核时间",
    align: "left",
    width: "160",
  },
  {
    field: "action",
    label: "操作",
    align: "center",
    width: "140",
  },
];

export const searchOptions = [
  {
    type: "input",
    field: "teacher",
    label: "申诉教师",
    placeholder: "按指申诉教师查询",
  },
  {
    type: "input",
    field: "student",
    label: "申诉对象",
    placeholder: "按申诉对象查询",
  },
  {
    type: "radio",
    field: "status",
    label: "申诉状态",
      options: [
        {
          id: "",
          name: "全部",
        },
        {
          id: 'YES',
          name: "通过",
        },
        {
          id: 'NO',
          name: "驳回",
        },
        {
          id: 'PROCESS',
          name: "审核中",
        },
      ],
  },
];
