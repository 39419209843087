var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{ref:"mainbox"},[(_vm.isAction)?_c('div',{ref:"actionbox",staticClass:"border-dashed border-b p-3"},[_vm._t("action")],2):_vm._e(),(_vm.isSearch)?_c('div',{ref:"searchbox",staticClass:"pt-3"},[_vm._t("search")],2):_vm._e(),_c('Table',_vm._b({ref:"table",attrs:{"root":this,"rows":_vm.tableData,"on-params":_vm.onParams},on:{"update:rows":function($event){_vm.tableData=$event},"onRowClick":_vm.onRowClick,"onSelectionChange":_vm.onSelectionChange},scopedSlots:_vm._u([(_vm.$scopedSlots["table-expand"] !== undefined)?{key:"table-expand",fn:function(ref){
var row = ref.row;
return [_vm._t("table-expand",null,{"row":row})]}}:null,_vm._l((_vm.columns),function(item){return {key:("table-header-" + (item['field'])),fn:function(ref){
var header = ref.header;
return [(_vm.$scopedSlots[("table-header-" + (item['field']))] !== undefined)?[_vm._t(("table-header-" + (item['field'])),null,{"header":header})]:[_vm._v(" "+_vm._s(item.label)+" ")]]}}}),_vm._l((_vm.columns),function(item){return {key:("table-item-" + (item['field'])),fn:function(ref){
var row = ref.row;
var $index = ref.$index;
return [(_vm.$scopedSlots[("table-item-" + (item['field']))] !== undefined)?[_vm._t('table-item-' + item['field'],null,{"row":row,"$index":$index})]:[_vm._v(" "+_vm._s(row[item["field"]] || row[item["field"]] === 0 ? row[item["field"]] : "-")+" ")]]}}})],null,true)},'Table',_vm.$props,false))],1)}
var staticRenderFns = []

export { render, staticRenderFns }