<template>
  <div ref="mainbox">
    <div v-if="isAction" ref="actionbox" class="border-dashed border-b p-3">
      <slot name="action"></slot>
    </div>
    <div v-if="isSearch" ref="searchbox" class="pt-3">
      <slot name="search"></slot>
    </div>
    <Table
      :root="this"
      ref="table"
      v-bind="$props"
      :rows.sync="tableData"
      :on-params="onParams"
      @onRowClick="onRowClick"
      @onSelectionChange="onSelectionChange"
    >
      <!-- 是否展开  -->
      <template
        v-if="$scopedSlots[`table-expand`] !== undefined"
        #table-expand="{ row }"
      >
        <slot :name="`table-expand`" :row="row"></slot>
      </template>

      <!--   自定义表头   -->
      <template
        v-for="item in columns"
        :slot="`table-header-${item['field']}`"
        slot-scope="{ header }"
      >
        <template
          v-if="$scopedSlots[`table-header-${item['field']}`] !== undefined"
        >
          <slot :name="`table-header-${item['field']}`" :header="header"></slot>
        </template>
        <template v-else>
          {{ item.label }}
        </template>
      </template>
      <!--  自定义内容   -->
      <template
        v-for="item in columns"
        :slot="`table-item-${item['field']}`"
        slot-scope="{ row, $index }"
      >
        <template
          v-if="$scopedSlots[`table-item-${item['field']}`] !== undefined"
        >
          <slot
            :name="'table-item-' + item['field']"
            :row="row"
            :$index="$index"
          ></slot>
        </template>
        <template v-else>
          {{
            row[item["field"]] || row[item["field"]] === 0
              ? row[item["field"]]
              : "-"
          }}
        </template>
      </template>
    </Table>
  </div>
</template>
<script>
import Table from "../table/index.vue";
export default {
  name: "",
  components: { Table },
  props: {
    // 是否有分页
    isPage: {
      type: Boolean,
      default: true,
    },

    // 是否有搜索
    isSearch: {
      type: Boolean,
      default: true,
    },
    // 是否有操作区
    isAction: {
      type: Boolean,
      default: true,
    },
    isIndex: {
      type: Boolean,
      default: true,
    },
    // 是否多选
    isSelection: {
      type: Boolean,
      default: true,
    },
    // 表格内容关键字
    tableKeyword: {
      type: String,
      default: "",
    },
    // 表格表头
    columns: {
      type: Array,
      default: () => [],
    },
    // api
    api: {
      type: String,
      default: "",
    },

    // 表格数据
    tableData: {
      type: Array,
      default: () => [],
    },
    height: {
      type: Number,
      default: 200,
    },

    //params 参数
    onParams: Function,
    // 结果
    onResult: Function,
  },
  data() {
    return {};
  },
  created() {},
  mounted() {},
  methods: {
    // 直接查询
    async onFind() {
      await this.$refs["table"].onFind();
    },

    // 保留当前分页查询
    onQuery() {
      this.$refs["table"].onQuery();
    },

    // table  change 事件
    onSelectionChange(data) {
      this.$emit("onSelectionChange", data);
    },

    // 行单击事件
    onRowClick(data) {
      this.$emit("onRowClick", data);
    },

    // 设置行高亮
    onSetCurrentRow(row) {
      console.log("row", row);
      this.$refs["table"].onSetCurrentRow(row);
    },
  },
};
</script>
<style lang="scss" scoped></style>
