<!--
 * ======================================
 * 说明： 管理员评分申诉管理
 * 作者： Silence
 * 文件： index.vue
 * 日期： 2023/5/21 23:04
 * 邮箱： 1106194754@qq.com
 * ======================================
-->
<template>
  <div>
    <Main
      class="h-full"
      ref="main"
      :on-params="onParams"
      :columns="columns"
      :is-selection="false"
      api="/statement/list_admin"
    >
      <template #search>
        <Search
          v-model="searchForm"
          :options="searchOptions"
          @onSearch="onFind()"
        ></Search>
      </template>
      <template #table-item-User="{ row }">
        {{ row["User"].username }}
      </template>
      <template #table-item-Student="{ row }">
        {{ row["Student"].username }}
      </template>
      <template #table-item-no="{ row }">
        {{ row["no"] }}（{{
          row["targetedList"]
            ? row["targetedList"].map((item) => item.name).join("，")
            : ""
        }}）
      </template>
      <template #table-item-status="{ row }">
        <el-tag v-if="row['status'] === 'YES'" type="success">通过</el-tag>
        <el-tag v-else-if="row['status'] === 'NO'" type="danger">驳回</el-tag>
        <el-tag v-else type="primary">审核中</el-tag>
      </template>

      <template #table-item-action="{ row }">
        <el-button
          type="text"
          v-if="row['status'] === 'PROCESS'"
          @click="handleComplaint(row, 'YES')"
          >通过</el-button
        >
        <el-button
          type="text"
          v-if="row['status'] === 'PROCESS'"
          class="text-red-600"
          @click="handleComplaint(row, 'NO')"
          >驳回</el-button
        >
      </template>
    </Main>
  </div>
</template>
<script>
import Main from "../components/main/index.vue";
import Search from "../components/search/index.vue";
import { columns, searchOptions } from "./config";
export default {
  name: "",
  components: { Main, Search },
  props: {},
  data() {
    return {
      columns,
      searchOptions,
      searchForm: {},
    };
  },
  computed: {},
  created() {},
  async mounted() {
    await this.onFind();
  },
  methods: {
    onParams() {
      return this.searchForm;
    },

    /**
     *  @actions:  查询列表数据
     *  @content:
     *  @Autor: Silence
     *  @Date: 2023/5/9 13:26
     *  @params:
     *          <参数名称>  参数标题  参数类型    默认值
     **/
    async onFind() {
      await this.$refs["main"].onFind();
    },
    /**
     *  @actions:  刷新列表
     *  @content:
     *  @Autor: Silence
     *  @Date: 2023/5/9 14:40
     *  @params:
     *          <参数名称>  参数标题  参数类型    默认值
     **/
    onRefresh() {
      this.$refs["main"].onQuery();
    },

    /**
     *  @actions:  修改评价指标
     *  @content:
     *  @Autor: Silence
     *  @Date: 2023/5/9 13:27
     *  @params:
     *          <参数名称>  参数标题  参数类型    默认值
     **/
    handleComplaint(row, status) {
      let message = "确定通过该条修改综合素质评价申诉请求吗？";
      let ret = "通过成功！";
      let confirm = "通过";
      if (status === "NO") {
        message = "确定驳回该条修改综合素质评价申诉请求吗？";
        ret = "驳回成功！";
        confirm = "驳回";
      }
      this.$confirm(message, "提示", {
        confirmButtonText: confirm,
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        this.$cloud
          .post("/statement/do", { id: row["id"], status })
          .then(() => {
            this.$message.success(ret);
            this.onRefresh();
          })
          .catch((err) => {
            this.$message.error(err);
          });
      });
    },
  },
};
</script>
<style lang="scss" scoped></style>
