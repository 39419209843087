var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('Main',{ref:"main",staticClass:"h-full",attrs:{"on-params":_vm.onParams,"columns":_vm.columns,"is-selection":false,"api":"/statement/list_admin"},scopedSlots:_vm._u([{key:"search",fn:function(){return [_c('Search',{attrs:{"options":_vm.searchOptions},on:{"onSearch":function($event){return _vm.onFind()}},model:{value:(_vm.searchForm),callback:function ($$v) {_vm.searchForm=$$v},expression:"searchForm"}})]},proxy:true},{key:"table-item-User",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(row["User"].username)+" ")]}},{key:"table-item-Student",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(row["Student"].username)+" ")]}},{key:"table-item-no",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(row["no"])+"（"+_vm._s(row["targetedList"] ? row["targetedList"].map(function (item) { return item.name; }).join("，") : "")+"） ")]}},{key:"table-item-status",fn:function(ref){
var row = ref.row;
return [(row['status'] === 'YES')?_c('el-tag',{attrs:{"type":"success"}},[_vm._v("通过")]):(row['status'] === 'NO')?_c('el-tag',{attrs:{"type":"danger"}},[_vm._v("驳回")]):_c('el-tag',{attrs:{"type":"primary"}},[_vm._v("审核中")])]}},{key:"table-item-action",fn:function(ref){
var row = ref.row;
return [(row['status'] === 'PROCESS')?_c('el-button',{attrs:{"type":"text"},on:{"click":function($event){return _vm.handleComplaint(row, 'YES')}}},[_vm._v("通过")]):_vm._e(),(row['status'] === 'PROCESS')?_c('el-button',{staticClass:"text-red-600",attrs:{"type":"text"},on:{"click":function($event){return _vm.handleComplaint(row, 'NO')}}},[_vm._v("驳回")]):_vm._e()]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }